<template>
  <b-container fluid v-if="$root.$data.me.access.TR1">
    <b-row>
      <b-col cols="12" class="mb-4">
        <page-snippet name="trends-header" />

        <b-card>
          <div slot="header">
            Filters
          </div>

          <b-container fluid>
            <b-row>
              <b-col class="mb-4">
                <p class="mb-1">Type:</p>
                <b-button-group>
                  <b-button
                      v-on:click="trendFilter.eventType = 'stream'"
                      v-bind:class="{ active: trendFilter.eventType == 'stream' }">
                    Stream
                  </b-button>
                  <b-button 
                      v-on:click="trendFilter.eventType = 'sale_track'"
                      v-bind:class="{ active: trendFilter.eventType == 'sale_track' }">
                    Track Sale
                  </b-button>
                  <b-button 
                      v-on:click="trendFilter.eventType = 'sale_album'"
                      v-bind:class="{ active: trendFilter.eventType == 'sale_album' }">
                    Album Sale
                  </b-button>
                </b-button-group>
              </b-col>

              <b-col cols="6" class="mb-4">
                <p class="mb-1">Time range:</p>

                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="trendFilter.timerange = '48h'"
                      v-bind:class="{ active: trendFilter.timerange == '48h' }">
                    Last 48 hours
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = '7d'"
                      v-bind:class="{ active: trendFilter.timerange == '7d' }">
                    Last week
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = '1m'"
                      v-bind:class="{ active: trendFilter.timerange == '1m' }">
                    Last month
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = '1y'"
                      v-bind:class="{ active: trendFilter.timerange == '1y' }">
                    Last year
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = 'custom'"
                      v-bind:class="{ active: trendFilter.timerange == 'custom' }">
                    Custom
                  </b-button>
                </b-button-group>

                <div v-if="trendFilter.timerange == 'custom'" class="mt-2">
                  From:
                  <b-form-datepicker v-model="trendFilter.dateFrom" class="datepicker" placeholder="Select date"></b-form-datepicker>

                  To:
                  <b-form-datepicker v-model="trendFilter.dateTo" class="datepicker" placeholder="Select date"></b-form-datepicker>
                </div>
              </b-col>

              <b-col cols="2" class="mb-4">
                <p class="mb-1">Results:</p>

                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="perPage = 5"
                      v-bind:class="{ active: perPage == 5 }">
                    5
                  </b-button>
                  <b-button
                      v-on:click="perPage = 10"
                      v-bind:class="{ active: perPage == 10 }">
                    10
                  </b-button>
                  <b-button
                      v-on:click="perPage = 25"
                      v-bind:class="{ active: perPage == 25 }">
                    25
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mb-4">
                <p class="mb-1">Tracks:</p>
                <ApiLookup v-model="trendFilter.selectedTracks" source="tracks/lookup" />
              </b-col>

              <b-col class="mb-4">
                <p class="mb-1">Albums:</p>
                <ApiLookup v-model="trendFilter.selectedAlbums" source="albums/lookup" />
              </b-col>

              <b-col class="mb-4">
                <p class="mb-1">Performing Artist:</p>
                <ApiLookup v-model="trendFilter.selectedAttributions" source="attributions/lookup" />
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mb-4">
                <p class="mb-1">Country:</p>
                <ApiLookup v-model="trendFilter.selectedCountries" :filter="trendFilter" source="analytics/trend-data/countries" />
              </b-col>

              <b-col class="mb-4">
                <p class="mb-1">Source:</p>
                <ApiLookup v-model="trendFilter.selectedSources" :filter="trendFilter" :searchOnMount="true" source="analytics/trend-data/sources" />
              </b-col>

              <b-col class="mb-4">
                <p class="mb-1">Gender:</p>
                <ApiLookup v-model="trendFilter.selectedGenders" :searchOnMount="true" :source="[{ key: 'male', value: 'Male'}, { key: 'female', value: 'Female'}, { key: 'other', value: 'Other'}]" />
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>

      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :canSelect="true" v-on:item-click="filterItem($event)" :trendFilter="trendFilter" :perPage="perPage" aggName="tracks" title="Top Tracks" column-title="Track" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :canSelect="true" v-on:item-click="filterItem($event)" :trendFilter="trendFilter" :perPage="perPage" aggName="albums" title="Top Albums" column-title="Album" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" aggName="outlets" title="Top Outlets" column-title="Outlet" v-bind:showCount="false" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :canSelect="true" v-on:item-click="filterItem($event)" :trendFilter="trendFilter" :perPage="perPage" aggName="countries" title="Top Countries" column-title="Country" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :canSelect="true" v-on:item-click="filterItem($event)" :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="source" title="Source" column-title="Source" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :canSelect="true" v-on:item-click="filterItem($event)" :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="gender" title="Gender" column-title="Gender"/></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="playFirst" title="First Play" column-title="First Play"/></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="playCompleted" title="Play Completed" column-title="Play Completed"/></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="playRepeat" title="Repeat Play" column-title="Repeat Play"/></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="playShuffle" title="Shuffle Play" column-title="Shuffle Play"/></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="playOffline" title="Offline Play" column-title="Offline Play"/></b-col>
    </b-row>

    <b-row>
      <b-col><TrendGraph :trendFilter="trendFilter" /></b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'Trends',
    components: {
      'page-snippet': () => import('@/components/PageSnippet.vue'),
      'TrendGraph': () => import('@/components/TrendGraph.vue'),
      'TrendList': () => import('@/components/TrendList.vue'),
      'ApiLookup': () => import('@/components/ApiLookup.vue'),
    },
    data () {
      return {
        trendFilter: {
          timerange: '7d',
          dateFrom: '',
          dateTo: '',
          eventType: 'stream',
          selectedTracks: [],
          selectedAlbums: [],
          selectedAttributions: [],
          selectedCountries: [],
          selectedSources: [],
          selectedGenders: [],
        },
        trendsLoading: false,
        perPage: 5,
      }
    },
    methods: {
      filterItem (data) {
        console.log(data)
        
        let item = data.item
        if (data.type == 'albums') {
          this.trendFilter.selectedAlbums.push({key: item.key, value: item.title})
        } else if (data.type == 'tracks') {
          this.trendFilter.selectedTracks.push({key: item.key, value: item.title})
        } else if (data.type == 'countries') {
          this.trendFilter.selectedCountries.push({key: item.key, value: item.title})
        } else if (data.type == 'source') {
          this.trendFilter.selectedSources.push({key: item.key, value: item.title})
        } else if (data.type == 'gender') {
          this.trendFilter.selectedGenders.push({key: item.key, value: item.title})
        } 
      },
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .datepicker {
    display: inline-block;
  }
</style>
