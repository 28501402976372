var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.$data.me.access.TR1
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mb-4", attrs: { cols: "12" } },
                [
                  _c("page-snippet", { attrs: { name: "trends-header" } }),
                  _c(
                    "b-card",
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(" Filters ")
                      ]),
                      _c(
                        "b-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-4" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Type:")
                                  ]),
                                  _c(
                                    "b-button-group",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.eventType ==
                                              "stream"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.eventType =
                                                "stream"
                                            }
                                          }
                                        },
                                        [_vm._v(" Stream ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.eventType ==
                                              "sale_track"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.eventType =
                                                "sale_track"
                                            }
                                          }
                                        },
                                        [_vm._v(" Track Sale ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.eventType ==
                                              "sale_album"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.eventType =
                                                "sale_album"
                                            }
                                          }
                                        },
                                        [_vm._v(" Album Sale ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-4", attrs: { cols: "6" } },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Time range:")
                                  ]),
                                  _c(
                                    "b-button-group",
                                    { staticClass: "mr-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.timerange == "48h"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.timerange = "48h"
                                            }
                                          }
                                        },
                                        [_vm._v(" Last 48 hours ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.timerange == "7d"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.timerange = "7d"
                                            }
                                          }
                                        },
                                        [_vm._v(" Last week ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.timerange == "1m"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.timerange = "1m"
                                            }
                                          }
                                        },
                                        [_vm._v(" Last month ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.timerange == "1y"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.timerange = "1y"
                                            }
                                          }
                                        },
                                        [_vm._v(" Last year ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active:
                                              _vm.trendFilter.timerange ==
                                              "custom"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.trendFilter.timerange =
                                                "custom"
                                            }
                                          }
                                        },
                                        [_vm._v(" Custom ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.trendFilter.timerange == "custom"
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _vm._v(" From: "),
                                          _c("b-form-datepicker", {
                                            staticClass: "datepicker",
                                            attrs: {
                                              placeholder: "Select date"
                                            },
                                            model: {
                                              value: _vm.trendFilter.dateFrom,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trendFilter,
                                                  "dateFrom",
                                                  $$v
                                                )
                                              },
                                              expression: "trendFilter.dateFrom"
                                            }
                                          }),
                                          _vm._v(" To: "),
                                          _c("b-form-datepicker", {
                                            staticClass: "datepicker",
                                            attrs: {
                                              placeholder: "Select date"
                                            },
                                            model: {
                                              value: _vm.trendFilter.dateTo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trendFilter,
                                                  "dateTo",
                                                  $$v
                                                )
                                              },
                                              expression: "trendFilter.dateTo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-4", attrs: { cols: "2" } },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Results:")
                                  ]),
                                  _c(
                                    "b-button-group",
                                    { staticClass: "mr-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          class: { active: _vm.perPage == 5 },
                                          on: {
                                            click: function($event) {
                                              _vm.perPage = 5
                                            }
                                          }
                                        },
                                        [_vm._v(" 5 ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: { active: _vm.perPage == 10 },
                                          on: {
                                            click: function($event) {
                                              _vm.perPage = 10
                                            }
                                          }
                                        },
                                        [_vm._v(" 10 ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: { active: _vm.perPage == 25 },
                                          on: {
                                            click: function($event) {
                                              _vm.perPage = 25
                                            }
                                          }
                                        },
                                        [_vm._v(" 25 ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-4" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Tracks:")
                                  ]),
                                  _c("ApiLookup", {
                                    attrs: { source: "tracks/lookup" },
                                    model: {
                                      value: _vm.trendFilter.selectedTracks,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trendFilter,
                                          "selectedTracks",
                                          $$v
                                        )
                                      },
                                      expression: "trendFilter.selectedTracks"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-4" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Albums:")
                                  ]),
                                  _c("ApiLookup", {
                                    attrs: { source: "albums/lookup" },
                                    model: {
                                      value: _vm.trendFilter.selectedAlbums,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trendFilter,
                                          "selectedAlbums",
                                          $$v
                                        )
                                      },
                                      expression: "trendFilter.selectedAlbums"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-4" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Performing Artist:")
                                  ]),
                                  _c("ApiLookup", {
                                    attrs: { source: "attributions/lookup" },
                                    model: {
                                      value:
                                        _vm.trendFilter.selectedAttributions,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trendFilter,
                                          "selectedAttributions",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trendFilter.selectedAttributions"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-4" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Country:")
                                  ]),
                                  _c("ApiLookup", {
                                    attrs: {
                                      filter: _vm.trendFilter,
                                      source: "analytics/trend-data/countries"
                                    },
                                    model: {
                                      value: _vm.trendFilter.selectedCountries,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trendFilter,
                                          "selectedCountries",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trendFilter.selectedCountries"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-4" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Source:")
                                  ]),
                                  _c("ApiLookup", {
                                    attrs: {
                                      filter: _vm.trendFilter,
                                      searchOnMount: true,
                                      source: "analytics/trend-data/sources"
                                    },
                                    model: {
                                      value: _vm.trendFilter.selectedSources,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trendFilter,
                                          "selectedSources",
                                          $$v
                                        )
                                      },
                                      expression: "trendFilter.selectedSources"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-4" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Gender:")
                                  ]),
                                  _c("ApiLookup", {
                                    attrs: {
                                      searchOnMount: true,
                                      source: [
                                        { key: "male", value: "Male" },
                                        { key: "female", value: "Female" },
                                        { key: "other", value: "Other" }
                                      ]
                                    },
                                    model: {
                                      value: _vm.trendFilter.selectedGenders,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trendFilter,
                                          "selectedGenders",
                                          $$v
                                        )
                                      },
                                      expression: "trendFilter.selectedGenders"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      canSelect: true,
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      aggName: "tracks",
                      title: "Top Tracks",
                      "column-title": "Track"
                    },
                    on: {
                      "item-click": function($event) {
                        return _vm.filterItem($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      canSelect: true,
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      aggName: "albums",
                      title: "Top Albums",
                      "column-title": "Album"
                    },
                    on: {
                      "item-click": function($event) {
                        return _vm.filterItem($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      aggName: "outlets",
                      title: "Top Outlets",
                      "column-title": "Outlet",
                      showCount: false
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      canSelect: true,
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      aggName: "countries",
                      title: "Top Countries",
                      "column-title": "Country"
                    },
                    on: {
                      "item-click": function($event) {
                        return _vm.filterItem($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      canSelect: true,
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      source: "spotify",
                      aggName: "source",
                      title: "Source",
                      "column-title": "Source"
                    },
                    on: {
                      "item-click": function($event) {
                        return _vm.filterItem($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      canSelect: true,
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      source: "spotify",
                      aggName: "gender",
                      title: "Gender",
                      "column-title": "Gender"
                    },
                    on: {
                      "item-click": function($event) {
                        return _vm.filterItem($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      source: "spotify",
                      aggName: "playFirst",
                      title: "First Play",
                      "column-title": "First Play"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      source: "spotify",
                      aggName: "playCompleted",
                      title: "Play Completed",
                      "column-title": "Play Completed"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      source: "spotify",
                      aggName: "playRepeat",
                      title: "Repeat Play",
                      "column-title": "Repeat Play"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      source: "spotify",
                      aggName: "playShuffle",
                      title: "Shuffle Play",
                      "column-title": "Shuffle Play"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
                },
                [
                  _c("TrendList", {
                    attrs: {
                      trendFilter: _vm.trendFilter,
                      perPage: _vm.perPage,
                      source: "spotify",
                      aggName: "playOffline",
                      title: "Offline Play",
                      "column-title": "Offline Play"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [_c("TrendGraph", { attrs: { trendFilter: _vm.trendFilter } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }